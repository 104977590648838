import './Project.css';
import { useEffect, useState } from "react";
import { getProjects } from "./../../hooks/getProjects";
import Project from "./Project";
import Modal from "./Modal";

export default function Projects(props){
    const [projects, setProjects] = useState([]);
    const [modalSettings, setModalSettings] = useState({view: 'options', project: null});
    // const [projectForm, setProjectForm ] = useState(null);
    // const [projectFormVisibility, setProjectFormVisibility] = useState(null);
    const [visibility, setVisibility] = useState(true);

    useEffect(() => {
        if(!projects.length)
            updateList();
    },[projects]);

    let showModal = (project = null, update = false) => {
        if(project){
            setModalSettings({view:'form', project: project});
        }else{
            setModalSettings({view:'form', project: null});
        }
        if(update)
            updateList();
    }

    let closeModal = (update = false) => {
        setModalSettings({view:'options', project: null});
        if(update)
            updateList();
    }


    let updateList = () => {
        getProjects().then((projects) => {
            setProjects(projects);
            if(projects && projects.length){
                props.setProject({id: projects[0].id, name: projects[0].project})
            }
        });
    }

    return (
        <div id="projects" className={visibility ? "show" : "hide"}>
            <div id="projects-options">
                <Modal modalSettings={modalSettings} view={modalSettings.view} project={modalSettings.project} close={closeModal} setView={showModal} />
                <button id="toggleProjectView" onClick={() => {setVisibility(!visibility)}} ><i className={visibility ? "las la-caret-square-left" : "las la-caret-square-right"}></i></button>
            </div>
            { projects && projects.length > 0 && 
                <ul style={{display:'flex', flexDirection:'column', height:'calc(100% - 32px)'}}>
                    {projects.map((project,i) => {
                        return <Project key={project.id}
                                        project={project} 
                                        active={props.selectedProject && project.id === props.selectedProject.id}
                                        setSelectedProject={props.setProject}
                                        showProject={showModal}
                                />;
                    })}
                    <li id='logout'><button onClick={props.logout} ><i className='las la-door-open'></i>Logout</button></li>
                </ul>}
        </div>
    );

}