import { useEffect, useState } from "react"
import { getMessage } from "../../hooks/getMessage";

export default function Details(props){
    const [infos, setInfos] = useState(null);

    useEffect(() => {
        if(props.url){
            getMessage(props.url).then((message) => {
                if(message)
                    setInfos(message);
            })
        }
    }, [props.url]);

    return <div id="message-infos" className={props.visible ? 'show' : 'hide'}>
        {infos &&
        <>
            <div className="base">
                <p className="type" data-type={infos.type.toLowerCase()}><span>{infos.type}</span></p>
                <p>ID <strong>{infos.id}</strong></p>
                <p className="tstamp">{infos.tstamp}</p>
                <button className="close" onClick={props.hide}><i className="las la-window-close"></i></button>
            </div>
            <div className="log-message">{infos.message}</div>
        </>}
</div>

}