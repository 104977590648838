import axios from "axios";

export async function deleteLogs(project){
    return await axios.delete('/projects/'+project+'/logs')
    .then(function(response){
        if(response.status == 200)
            return true;

        return false;
    })
    .catch(function (error){
        return false
    });
}