import { useEffect, useState } from "react";
import { deleteProject } from "../../hooks/deleteProject";
import { getProject } from "../../hooks/getProject";

export default function Edit(props){
    const [project, setProject] = useState({
        id: 0,
        project: '',
        logger: []
    });

    useEffect(() => {
        if(props.project && props.project !== project.id)
            getProject(props.project).then((project) => setProject(project));
    },[props.project]);

    let handleDelete = () =>{
        deleteProject(project.id).then((result) => {
            if(result !== false)
                props.close(true);
        });
    }

    return <div id="projectform" >
        <button type="button" onClick={props.close}><i className="las la-times"></i></button>
        {project &&
            <>
                <h2><strong>{project.id}</strong> - {project.project}</h2>
                <input type="text" placeholder="Name" value={project.project} disabled/>
                {project.logger.length && project.logger.map(
                    (logger,i) => <div key={i} className="logger"><input value={logger.origin} disabled/><input value={logger.code} disabled/></div>
                )}
            </>
        }
        <button className="delete"  onClick={handleDelete}><i className="las la-trash"></i> Löschen</button>
    </div>
}