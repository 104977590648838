import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

// change in prod/dev
axios.defaults.baseURL = 'https://scarab.pmode.cloud';
// axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';  //TODO 'application/json';
axios.defaults.headers.common['X-Api-Key'] = '328cdb7eb10257f32a829c60f4365de71aae6a26b482041c';

// axios = axios.create({
//   withCredentials: true,
//   baseURL: 'https://scarab.pmode.cloud',
//   timeout: 5000,
//   headers: {
//     'X-Api-Key': '328cdb7eb10257f32a829c60f4365de71aae6a26b482041c',
//     'Content-Type': 'multipart/form-data'
//   }
// });

Notification.requestPermission();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
