import './App.css';
import { useEffect, useState } from 'react';
import Login from './components/auth/Login';
import axios from 'axios';
import Projects from './components/project/Projects';
import Logs from './components/logs/Logs';

function App() {
    const [logged, isLoggedIn] = useState(false);
    const [online, isOnline] = useState(false);
    const [project, setProject] = useState(null);

    useEffect(() => {
        axios.options('/')
            .then((response) => {
                isOnline(response.status === 200);
                //TODO json bool real value
                isLoggedIn(response.data && response.data === 'true');
            })
            .catch(() => {
                isLoggedIn(false);
                isOnline(false);
            });
    });

    let logout = () => {
        axios.post('/logout').then(() => {isLoggedIn(false)});
    }

    return logged && online ?
    <div id="app" className='online'>
        <Projects selectedProject={project} setProject={setProject} logout={logout}/>
        <Logs project={project}/>
    </div>:
    (online ?
    <div id="app" className='online'>
        <Login  isLoggedIn={isLoggedIn}/>
    </div>:
    <div id="app" className='container' style={{display:'block', textAlign: 'center', padding:'45px'}} >
        <h2>Sie sind leider Offline. Überprüfen Sie Ihre Netzwerkeinstellungen</h2>
    </div>)
}

export default App;