import { useState } from "react"

export default function Filter(props){
    const [filter, setFilter] = useState({
        error: false, 
        warning: false, 
        info: false
    });

    return <div id="filter">
        <button className="updateLogs" title="Update logs for this project" onClick={() => {props.updateLogs(filter)}}><i className="las la-sync"></i></button>
        {/* <button className="notify" title={notifyTitle} ><i className='k'></i></button> */}
        <button className="clearLogs" title="empty logs for this project" onClick={props.clearLogs}><i className="las la-trash-alt"></i></button>
        <div className="log-types">
            <input type="checkbox" 
                onClick={() => {
                    let newFilter = {...filter, error: !filter.error};
                    setFilter(newFilter); 
                    props.updateLogs(newFilter);
                }} 
                id="error-filter" 
                value="error"/>
            <label htmlFor="error-filter">ERROR</label>
            <input type="checkbox" 
                onClick={() => {
                    let newFilter = {...filter, warning: !filter.warning};
                    setFilter(newFilter); 
                    props.updateLogs(newFilter);
                }} 
                id="warning-filter" 
                value="warning"/>
            <label htmlFor="warning-filter">WARNING</label>
            <input type="checkbox" 
                onClick={() => {
                    let newFilter = {...filter, info: !filter.info};
                    setFilter(newFilter); 
                    props.updateLogs(newFilter);
                }} 
                id="info-filter" 
                value="info"/>
            <label htmlFor="info-filter">INFO</label>
        </div>
    </div>
}