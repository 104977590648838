import axios from "axios";

export async function postProject(project){
    let postdata = new FormData();
    postdata.append("name",project.name);
    postdata.append("origin",project.url);
    return await axios.post('/projects',postdata).then((response) => {
        if(response.status === 201)
            return response.data.id;

        return false;
    }).catch((error) => {
        return false;
    });
}