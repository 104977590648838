import './Login.css';
import { useState } from "react";
import axios from 'axios';

export default function Login(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState([]);

    const login = (e) => {
        let errors = [];
        if(!email || !email.length)
           errors.push('Bitte E-Mail-Addresse eingeben');
           
        if(!password || !password.length)
           errors.push('Bitte Passwort eingeben');

        if(!errors.length){
            axios.post('/login', {}, 
            {
                auth: {
                    username: email,
                    password: password
                }
            })
            .then(function (response) {
                if(response.status === 200)
                {
                    console.log('Logged IN');
                    props.isLoggedIn(true);
                }
            })
            .catch(function (error) {
                setErrors(["Login fehlgeschlagen"]);
            });
        }

        setErrors(errors);
        e.preventDefault();
    };
  

    return <div id="login">
        <h1>Login</h1>
        { errors && errors.length > 0 && errors.map((message, i) => {
            return <p key={i} className="error">{message}</p>
        })}
        <form method="POST" onSubmit={login}>
            <p>Bitte melde dich an:</p>
            <input type="email" autoFocus placeholder="E-Mail-Adresse" name="email" value={email} onChange={(e) => {setEmail(e.target.value)}}/>
            <input type="password" placeholder="Passwort" name="password" value={password} onChange={(e) => {setPassword(e.target.value)}}/>
            <button className="btn">Anmelden</button>
        </form>
    </div>;
}