import './Logs.css';
import { useEffect, useState } from "react"
import { getLogs } from '../../hooks/getLogs';
import Message from './Message';
import Details from './Details';
import { deleteLogs } from '../../hooks/deleteLogs';
import Filter from './Filter';
import { getOlderLogs } from '../../hooks/getOlderLogs';

export default function Logs(props){
    const [loading, isLoading] = useState(false);
    const [logs, setLogs] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState([]);
    const [showDetails, detailsVisible] = useState(false);
    const [oldest, setOldest] = useState(null);
    const [filter, setFilter] = useState(null);

    let selectMessage = (url) => {
        detailsVisible(true);
        setSelectedMessage(url);
    };

    let hide  = () => {
        detailsVisible(false);
    }

    useEffect(() => {
        if(props?.project !== null && props.project.id !== null) {
            getLogs(props.project.id).then((logs) => {
                setLogs(logs);
                if(logs && logs.length)
                    setOldest(new Date(logs[logs.length-1].tstamp));
            });
        }
    },[props?.project?.id]);


    let clearLogs = () => {
        deleteLogs(props.project.id).then(() => { setLogs([]); });
    }

    let updateLogs = (filter) => {
        setFilter(filter);
        getLogs(props.project.id,filter).then((logs) => {
            setLogs(logs);
        });
    }

    let handleScroll = (event) => {
        const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
        if(!loading, bottom)
        {
            console.log('hi');
            isLoading(true);
            getOlderLogs(props.project.id,filter, oldest).then((logs) => {
                console.log(logs);
                setLogs(logs);
                isLoading(false);
            });
        }
    }

    return <div id="log-view">
        <Filter clearLogs={clearLogs} updateLogs={updateLogs}/>
        <div id="logs" onScroll={handleScroll}>
            {logs.length ?
            <>
                {selectedMessage &&
                    <Details url={selectedMessage} visible={showDetails} hide={hide}/>}
                <table>
                    <tbody>
                        {logs.map((message) => {
                            return <Message key={message.id} message={message} selectedMessage={selectMessage}/>
                        })}
                    </tbody>
                </table>
            </>:
            <p style={{display:'block', textAlign:'center'}}>No Logs</p>}
        </div>
    </div>
}