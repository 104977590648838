import axios from "axios";

export async function getLogs(project, filter = null){
    let formData = null;
    if(filter){
        formData = new FormData();

        if(filter && Object.keys(filter).length > 0)
            formData.append('filter', JSON.stringify(filter));
    }

    if(formData) {
        return await axios.post('/projects/'+project+'/logs',formData).then((response) => {
            if(response.status === 200)
                return response.data;

            return [];
        }).catch((error) => {
            return [];
        });
    }else{
        return await axios.get('/projects/'+project+'/logs').then((response) => {
            if(response.status === 200)
                return response.data;

            return [];
        }).catch((error) => {
            return [];
        });
    }
}