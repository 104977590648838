import { useState } from "react";
import { postProject } from "../../hooks/postProject";

export default function New(props) {
    const [failed, hasFailed] = useState(false);
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');

    let handleSubmit = (e) =>{
        let failed = false;

        if(!name || !name.length || !url || !url.length)
            failed = true;

            hasFailed(failed);
        if(!failed){
            postProject({name: name, url: url}).then((id) => {
                if(id !== false){
                    props.submitted(id,true);
                }
            });
        }
        e.preventDefault();
    }

    return <form id="projectform" onSubmit={handleSubmit}>
        <button type="button" onClick={props.close}><i className="las la-times"></i></button>
        <h2>Neues Projekt</h2>
        {failed && <p>Bitte alle Werte angeben um Projekt zu speichern</p>}
        <input type="text" name="project" placeholder="Name" value={name} onChange={(e) => {setName(e.target.value)}}/>
        <input type="url" name="origin" placeholder="URL" value={url} onChange={(e) => {setUrl(e.target.value)}}/>
        <button type="submit" ><i className="las la-save"></i> Speichern</button>
    </form>
}