import axios from "axios";

export async function getProject(project){
    return await axios.get('/projects/'+project).then((response) => {
        if(response.status === 200)
            return response.data;

        return {};
    }).catch((error) => {
        return {};
    });
}