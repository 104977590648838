import axios from "axios";

export async function deleteProject(project){
    return await axios.delete('/projects/'+project).then((response) => {
        if(response.status === 204)
            return true;

        return false;
    }).catch((error) => {
        return false;
    });
}