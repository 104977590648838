import axios from "axios";

export async function getProjects(){
    return await axios.get('/projects').then((response) => {
        if(response.status === 200)
            return response.data;

        return [];
    }).catch((error) => {
        return [];
    });
}