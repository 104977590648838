import axios from "axios";

export async function getOlderLogs(project, filter, timestamp){
    let formData = new FormData();

    if(filter && Object.keys(filter).length > 0)
        formData.append('filter', JSON.stringify(filter));


    formData.append('oldest', timestamp.toUTCString())

    return await axios.post('/projects/'+project+'/logs',formData).then((response) => {
        if(response.status === 200)
            return response.data;

        return [];
    }).catch((error) => {
        return [];
    });
}