import axios from "axios";

export async function getMessage(url){
    return await axios.get(url).then((response) => {
        if(response.status === 200)
            return response.data;

        return null;
    }).catch((error) => {
        return null;
    });
}